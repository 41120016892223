import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import categorias from './modules/categorias'
import main from './modules/main'
import usuarios from './modules/usuarios'
import blog from './modules/blog'
import blog2 from './modules/blog2'
import blog3 from './modules/blog3'
import blog4 from './modules/blog4'
import tag from './modules/tag'
import blog_category from './modules/blog_category'
import colaboradores from './modules/colaboradores';
import project_category from './modules/project_category'
import project from './modules/project'
import videos from './modules/videos'
import servidores from './modules/servidores'
import videos_category from './modules/videos_category'
import historias from './modules/historias'
import historias2 from './modules/historias2'
import clients from './modules/clients'
import paquete from './modules/paquete'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main:main,
    usuarios:usuarios,
    categorias:categorias,
    blog:blog,
    blog2:blog2,
    blog3:blog3,
    blog4:blog4,
    historias:historias,
    historias2:historias2,
    tag:tag,
    blog_category:blog_category,
    colaboradores: colaboradores,
    project_category:project_category,
    project:project,
    videos: videos,
    videos_category:videos_category,
    servidores:servidores,
    clients:clients,
    paquete,

  }
})
