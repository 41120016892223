import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'

Vue.use(VueRouter)

const routes = [
  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      log: true,
      admin: true,
      auth: true,
    }
  },

  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta: {
      log: true,
      admin: true,
      auth: false
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    beforeEnter: async (to, from, next) => {
      let tokenexp = await userService.tokenexp()

      if (tokenexp) {
        localStorage.clear(); //limpiar el localstorage
        next()
      }

      next();
    },
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/magazine/:url',
    name: 'previewBlog',
    component: () => import('../views/blog/previewBlog'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/previsualizacion/proyecto/:url',
    name: 'previewProject',
    component: () => import('../views/project/previewProject'),
    meta: {
      log: true,
      admin: true,
      auth: true
    }
  },

  {
    path: '/#LastProjects',
    name: 'LastProjects',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/home/latest-proyects',
    name: 'latest-proyects',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/home/about',
    name: 'about',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/tienda-en-linea',
    name: 'Tienda en línea',
    component: () => import('../views/TiendaLanding'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/desarrollo-tienda-en-linea',
    name: 'Desarrollo tienda en línea',
    component: () => import('../views/TiendaLandingVar1'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/desarrollo-web',
    name: 'Desarrollo web',
    component: () => import('../views/DesarrolloLanding'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/diseno-de-paginas-web',
    name: 'Diseño de páginas web ',
    component: () => import('../views/DesarrolloLandingVar1'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/diseno-web',
    name: 'Diseño de páginas web ',
    component: () => import('../views/DesarrolloLandingVar2'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/desarrollo-web-guadalajara',
    name: 'Desarrollo web en Guadalajara',
    component: () => import('../views/DesarrolloLanding-Guadalajara'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/desarrollo-web-monterrey',
    name: 'Desarrollo web en Monterrey',
    component: () => import('../views/DesarrolloLanding-Monterrey'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/desarrollo-web-puebla',
    name: 'Desarrollo web en Puebla',
    component: () => import('../views/DesarrolloLanding-Puebla'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/desarrollo-web-merida',
    name: 'Desarrollo web en Merida',
    component: () => import('../views/DesarrolloLanding-Merida'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/desarrollo-web-hermosillo',
    name: 'Desarrollo web en Hermosillo',
    component: () => import('../views/DesarrolloLanding-Hermosillo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/branding',
    name: 'Agencia de branding',
    component: () => import('../views/NamingNBranding'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/branding-guadalajara',
    name: 'Agencia de branding guadalajara',
    component: () => import('../views/NamingNBranding-guadalajara'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/branding-monterrey',
    name: 'Agencia de branding monterrey',
    component: () => import('../views/NamingNBranding-monterrey'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/branding-cancun',
    name: 'Agencia de branding cancun',
    component: () => import('../views/NamingNBranding-cancun'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/branding-tijuana',
    name: 'Agencia de branding tijuana',
    component: () => import('../views/NamingNBranding-tijuana'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/home/team',
    name: 'team',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/home/stories',
    name: 'stories',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/home/services',
    name: 'services',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/home/partners',
    name: 'partners',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/home/magazine',
    name: 'magazine',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/home/contact-us',
    name: 'contact-us',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/politicas',
    name: 'Politicas',
    component: () => import('../views/politicas'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/portafolio',
    name: 'Portafolio',
    component: () => import('../views/Portafolio'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/brief',
    name: 'Brief',
    component: () => import('../views/Brief'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/historias-de-exito',
    name: 'Historias de Exito',
    component: () => import('../views/HistoriasDeExito'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/portafolio/:categoria',
    name: 'PortafolioCat',
    component: () => import('../views/Portafolio'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/portafolio-post/por-kilo',
    name: 'Por Kilo portafolio white',
    component: () => import('../views/landing/porkilo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/portafolio-post/barrio-alegre',
    name: 'Barrio Alegre portafolio white',
    component: () => import('../views/landing/barrioAlegre'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-post/holy-o',
    name: 'Holy O portafolio white',
    component: () => import('../views/landing/holyO'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/portafolio-post/:url',
    name: 'PortafolioPage white',
    component: () => import('../views/PortafolioContent'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/development-post/pcvirgo',
    name: 'PC Virgo portafolio white',
    component: () => import('../views/Development/virgo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/development-post/alter',
    name: 'Alter portafolio white',
    component: () => import('../views/Development/alter'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },



  {
    path: '/proximamente',
    name: 'Proximamente',
    component: () => import('../views/Proximamente'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  // WIP
  // {
  //   path: '/en',
  //   name: 'Home ',
  //   component: () => import('../views/home_en'),
  //   meta: {
  //     log: false,
  //     admin: false,
  //     auth: false
  //   }
  // },
  // WIP

  {
    path: '/services/development',
    name: 'Development',
    component: () => import('../views/DevSection'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/tienda',
    name: 'Tienda',
    component: () => import('../views/Tienda'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },



  {
    path: '/magazine',
    name: 'BlogTest',
    component: () => import('../views/BlogTest'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/lite-sites',
    name: 'LiteSites',
    component: () => import('../views/LiteSites'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding',
    name: 'Naming and branding',
    component: () => import('../views/landings/NamingNBranding'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding-san-diego',
    name: 'Naming and branding san diego',
    component: () => import('../views/landings/NamingNBranding-sandiego'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding-chicago',
    name: 'Naming and branding Chicago',
    component: () => import('../views/landings/NamingNBranding-chicago'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding-los-angeles',
    name: 'Naming and branding Los Angeles',
    component: () => import('../views/landings/NamingNBranding-los-angeles'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding-dallas',
    name: 'Naming and branding Dallas',
    component: () => import('../views/landings/NamingNBranding-dallas'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding-scottsdale',
    name: 'Naming and branding Scottsdale',
    component: () => import('../views/landings/NamingNBranding-scottsdale'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding-san-francisco',
    name: 'Naming and branding San Francisco',
    component: () => import('../views/landings/NamingNBranding-san-francisco'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding-phoenix',
    name: 'Naming and branding Phoenix',
    component: () => import('../views/landings/NamingNBranding-phoenix'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/naming-and-branding-tucson',
    name: 'Naming and branding Tucson',
    component: () => import('../views/landings/NamingNBranding-tucson'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency',
    name: 'Branding Agency',
    component: () => import('../views/landings/BrandingAgency'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency-chicago',
    name: 'Branding Agency Chicago',
    component: () => import('../views/landings/BrandingAgency-chicago'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency-dallas',
    name: 'Branding Agency Dallas',
    component: () => import('../views/landings/BrandingAgency-dallas'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency-los-angeles',
    name: 'Branding Agency Los Ángeles',
    component: () => import('../views/landings/BrandingAgency-los-angeles'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency-phoenix',
    name: 'Branding Agency Phoenix',
    component: () => import('../views/landings/BrandingAgency-phoenix'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency-san-francisco',
    name: 'Branding Agency San Francisco',
    component: () => import('../views/landings/BrandingAgency-san-francisco'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency-san-diego',
    name: 'Branding Agency San Diego',
    component: () => import('../views/landings/BrandingAgency-sandiego'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency-scottsdale',
    name: 'Branding Agency Scottsdale',
    component: () => import('../views/landings/BrandingAgency-scottsdale'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/branding-agency-tucson',
    name: 'Branding Agency Tucson',
    component: () => import('../views/landings/BrandingAgency-tucson'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency',
    name: 'Creative Agency',
    component: () => import('../views/landings/CreativeAgency'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency-chicago',
    name: 'Creative Agency Chicago',
    component: () => import('../views/landings/CreativeAgency-chicago'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency-dallas',
    name: 'Creative Agency Dallas',
    component: () => import('../views/landings/CreativeAgency-dallas'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency-los-angeles',
    name: 'Creative Agency Los Ángeles',
    component: () => import('../views/landings/CreativeAgency-los-angeles'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency-phoenix',
    name: 'Creative Agency phoenix',
    component: () => import('../views/landings/CreativeAgency-phoenix'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency-san-francisco',
    name: 'Creative Agency San Francisco',
    component: () => import('../views/landings/CreativeAgency-san-francisco'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency-san-diego',
    name: 'Creative Agency San Diego',
    component: () => import('../views/landings/CreativeAgency-sandiego'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency-scottsdale',
    name: 'Creative Agency Scottsdale',
    component: () => import('../views/landings/CreativeAgency-scottsdale'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/creative-agency-tucson',
    name: 'Creative Agency Tucson',
    component: () => import('../views/landings/CreativeAgency-tucson'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-development',
    name: 'Web Development',
    component: () => import('../views/landings-web/DevelopmentLanding'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-development-denver',
    name: 'Web Development in Denver',
    component: () => import('../views/landings-web/DevelopmentLanding-denver'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-development-tucson',
    name: 'Web Development in Tucson',
    component: () => import('../views/landings-web/DevelopmentLanding-tucson'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-development-phoenix',
    name: 'Web Development in Phoenix',
    component: () => import('../views/landings-web/DevelopmentLanding-phoenix'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-development-los-angeles',
    name: 'Web Development in Los Ángeles',
    component: () => import('../views/landings-web/DevelopmentLanding-los-angeles'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-development-san-diego',
    name: 'Web Development in San Diego',
    component: () => import('../views/landings-web/DevelopmentLanding-san-diego'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-design-company',
    name: 'Web Design Company',
    component: () => import('../views/landings-web/DesignLanding'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-design-company-denver',
    name: 'Web Design Company in Denver',
    component: () => import('../views/landings-web/DesignLanding-denver'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-design-company-tucson',
    name: 'Web Design Company in Tucson',
    component: () => import('../views/landings-web/DesignLanding-tucson'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-design-company-phoenix',
    name: 'Web Design Company in Phoenix',
    component: () => import('../views/landings-web/DesignLanding-phoenix'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-design-company-los-angeles',
    name: 'Web Design Company in Los Ángeles',
    component: () => import('../views/landings-web/DesignLanding-los-angeles'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/web-design-company-san-diego',
    name: 'Web Design Company in San Diego',
    component: () => import('../views/landings-web/DesignLanding-san-diego'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/agencia-de-marketing',
    name: 'agencia de marketing',
    component: () => import('../views/landingsAgencia/AgenciaMarketing'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-marketing-monterrey',
    name: 'agencia de marketing monterrey',
    component: () => import('../views/landingsAgencia/AgenciaMarketing-Monterrey'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-marketing-guadalajara',
    name: 'agencia de marketing guadalajara',
    component: () => import('../views/landingsAgencia/AgenciaMarketing-Guadalajara'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-marketing-puebla',
    name: 'agencia de marketing puebla',
    component: () => import('../views/landingsAgencia/AgenciaMarketing-Puebla'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-marketing-queretaro',
    name: 'agencia de marketing queretaro',
    component: () => import('../views/landingsAgencia/AgenciaMarketing-Queretaro'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-marketing-hermosillo',
    name: 'agencia de marketing hermosillo',
    component: () => import('../views/landingsAgencia/AgenciaMarketing-Hermosillo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-marketing-chihuahua',
    name: 'agencia de marketing chihuahua',
    component: () => import('../views/landingsAgencia/AgenciaMarketing-Chihuahua'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad',
    name: 'agencia de publicidad',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-monterrey',
    name: 'agencia de publicidad monterrey',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad-Monterrey'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-puebla',
    name: 'agencia de publicidad puebla',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad-Puebla'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-queretaro',
    name: 'agencia de publicidad queretaro',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad-Queretaro'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-guadalajara',
    name: 'agencia de publicidad guadalajara',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad-Guadalajara'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-hermosillo',
    name: 'agencia de publicidad hermosillo',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad-Hermosillo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-chihuahua',
    name: 'agencia de publicidad chihuahua',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad-Chihuahua'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-y-diseno',
    name: 'agencia de publicidad y diseño',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad&Diseno'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-y-diseno-monterrey',
    name: 'agencia de publicidad y diseño monterrey',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad&Diseno-Monterrey'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-y-diseno-guadalajara',
    name: 'agencia de publicidad y diseño guadalajara',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad&Diseno-Guadalajara'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-y-diseno-puebla',
    name: 'agencia de publicidad y diseño puebla',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad&Diseno-Puebla'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-y-diseno-queretaro',
    name: 'agencia de publicidad y diseño queretaro',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad&Diseno-Queretaro'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-y-diseno-hermosillo',
    name: 'agencia de publicidad y diseño hermosillo',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad&Diseno-Hermosillo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-de-publicidad-y-diseno-chihuahua',
    name: 'agencia de publicidad y diseño chihuahua',
    component: () => import('../views/landingsAgencia/AgenciaPublicidad&Diseno-Chihuahua'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/diseno-one-page',
    name: 'Diseño one page',
    component: () => import('../views/landing-DesignOnePage/designOnePage'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/diseno-de-landing-page-y-servicio-de-google-ads',
    name: 'Diseño de landing page y servicio de google ads',
    component: () => import('../views/landing-GoogleAds/landingPage&GoogleAds'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/brand-builders',
    name: 'Agencia de branding',
    component: () => import('../views/BrandBuilders'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/servicio-branding',
    name: 'Agencia de branding',
    component: () => import('../views/ServicioBranding'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/brand-builders',
    name: 'Branding Agency',
    component: () => import('../views/BrandBuildersEn'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-desarrollo-web',
    name: 'Agencia de desarrollo web',
    component: () => import('../views/AgenciaDesarrolloWeb'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/disenos-sitios-web',
    name: 'Diseños de sitios web',
    component: () => import('../views/DisenoSitiosWeb'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/desarrollo-sitios-web',
    name: 'Desarrollo de sitios web',
    component: () => import('../views/DesarrolloSitiosWeb'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-musho-cevishe',
    name: 'Portafolio Musho Cevishe',
    component: () => import('../views/PortafolioMushoCevishe.vue'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-litesites',
    name: 'Portafolio Litesites',
    component: () => import('../views/PortafolioLiteSites.vue'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-babymo',
    name: 'Portafolio Baby Mo',
    component: () => import('../views/PortafolioBabymo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-holy-o',
    name: 'Portafolio Holy O',
    component: () => import('../views/PortafolioHoly'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-tribo',
    name: 'Portafolio Tribo',
    component: () => import('../views/PortafolioTribo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-sweat&wear',
    name: 'Portafolio weat&Wear',
    component: () => import('../views/PortafolioSweat&Wear'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-la-verbena',
    name: 'Portafolio La verbena',
    component: () => import('../views/PortafolioLaVerbena'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-bamx',
    name: 'Portafolio Bamx',
    component: () => import('../views/landingDesarrolloPortafolio/desarrolloPortafolioBamx'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/portafolio-davis',
    name: 'Portafolio Davis',
    component: () => import('../views/landingDesarrolloPortafolio/desarrolloPortafolioDavis'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/en/online-store',
    name: 'online-store',
    component: () => import('../views/TiendaLandingEn'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/digital-marketing',
    name: 'Digital Marketing',
    component: () => import('../views/DigitalMarketing'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  {
    path: '/agencia-seo',
    name: 'Agencia SEO',
    component: () => import('../views/AgenciaSeo'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
}


)



router.beforeEach(async (to, from, next) => {

  let meta_admin = to.matched.some(record => record.meta.admin);
  let meta_log = to.matched.some(record => record.meta.log);
  let meta_auth = to.matched.some(record => record.meta.auth);

  let tokenexp = await userService.tokenexp()
  let check = false //comprobar si esta logeado
  let admin = false;
  let type = ''


  if (tokenexp) { // ver que el token no este expirado.
    localStorage.clear(); //limpiar el localstorage
    next()
  }


  let response = await userService.getData()

  if (response.status == 'error') {
    localStorage.clear()
  }


  if (response.status == 'success') { //comprobar que sea un login valido.
    check = true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin
    type = isAdmin.type

  }

  let ath = await userService.getAth();


  // borrar el localstorage cada que vayamos a login.

  if (to.path === '/login' && check) {
    localStorage.clear()
    next()
  }


  if (meta_log) {
    if (check) {
      if (meta_admin) {
        if (admin) {

          if (meta_auth) {
            if (ath) {
              next();
            } else {
              localStorage.clear()
              next('/login')
            }
          } else { //...proximamente rutas que no requieran autentificacion
            next()
          }

        } else {
          localStorage.clear()
          next('/')
        }
      } else {
        if (admin) { //no requiere admin pero es admin
          next()
        } else {
          next()
        }
      }
    } else {
      next('/login')
    }


  } else {

    if (check) {
      if (admin) {
        next()
      } else {
        next();
      }
    } else {
      next();
    }

  }


});












export default router

