import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'vue-dayjs'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JQuery from 'jquery'
import VueDragscroll from 'vue-dragscroll'

import Vue2Editor from "vue2-editor";
import VueRellax from 'vue-rellax'
import VueCarousel from 'vue-carousel';
import AOS from 'aos';
import VueMeta from 'vue-meta';
import 'aos/dist/aos.css'


Vue.use(VueCarousel);
Vue.use(VueMeta);

Vue.use(VueRellax)


Vue.use(Vue2Editor);
Vue.use(VueDragscroll)
window.$ = JQuery
let urlpath = require('./global/url')

Vue.use(dayjs)



Vue.use(VueAxios, axios)

axios.defaults.baseURL =urlpath.url();

Vue.config.productionTip = false

new Vue({
  router,
  mounted() {
    AOS.init()
  },
  store, 
  render: h => h(App),
}).$mount('#app')
