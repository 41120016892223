import axios from "axios";

export default {

  resendCode(user, headers){
    return axios.post(`/reenviar-codigo`, user, headers);
  },

  login(user, headers){
    return axios.post(`/login`, user, headers);
  },

  getInfoUser(data, headers){
    return axios.post(`/info_usr`, data, {headers});
  },



  getAllInfo(option, headers){
    return axios.get(`/${option}`, {headers});
  },

  getStatusImage(payload, headers){
    return axios.get(`/${payload.option}-img/${payload.image}`, {headers});
  },


  getInfoById(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
 
  search(data, headers){
    return axios.get(`/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },

  addSuscriptor(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post('/subs/user/suscribe', payload, {headers});
  },
  enviarMailContacto(data, headers){
    return axios.post('j43-wdrDE4s_wffs2/contacto', data, headers);
  },
  enviarBrief(data, headers){
    return axios.post('j43-wdrDE4s_wffs2/brief', data, headers);
  },

 
  //metodos de eliminar, editar y agregar
  deleteItem(payload,token){
    return axios.delete(`/${payload.option}/delete`, {
        data: {
          id:payload.id
        },
        headers: {
          Authorization: token 
        } 
      });
  },

  deleteItemParams(payload,token){
    return axios.delete(`/${payload.option}/delete/${payload.id}`, {
        data: {},
        headers: {
          Authorization: token 
        } 
      });
  },
  
  editItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${payload.option}/update`, payload.item, {headers} );
  },

  updateItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${payload.option}/update/${payload.id}`, payload.item, {headers} );
  },

  editImage(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${payload.option}/upload/image/${payload.id}`, payload.item, {headers} );
  },

  addItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${payload.option}/create`, payload.item, {headers});
  },



  

};